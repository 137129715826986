<template>
  <div class="grid">
    <div class="flex col-12 justify-content-center" v-if="loading" style="position: absolute;top: 50%;transform: translate(0, -50%);">
      <ProgressSpinner></ProgressSpinner>
    </div>
    <div class="col-12 lg:col-4" v-show="!loading">
      <Card>
        <template #title>Поиск заявки</template>
        <template #content>
          <AbstractForm
            ref="form"
            :loading="formLoading"
            v-model="searchForm"
            :on-commit="searchApplication"
            :after-clear="() => {this.application = null; this.borrower = null}"
            commit-label="Найти"
            commit-icon="pi pi-search"
          ></AbstractForm>
        </template>
      </Card>
    </div>
    <div class="col-12 lg:col-8" v-if="application !== null && !loading">
      <Card>
        <template #title>Заявка {{ application.uuid }}</template>
        <template #content>
          <p><strong>Сумма: </strong>{{ application.amount }}</p>
          <p><strong>Статус: </strong>{{ application.status }}</p>
          <p><strong>Дата создания: </strong>{{ formatDate(application.createdAt) }}</p>
          <p><strong>Дата обновления: </strong>{{ formatDate(application.updatedAt) }}</p>
          <br/>
          <p><strong>UUID заёмщика: </strong>{{ borrower.uuid }}</p>
          <p><strong>ФИО: </strong>{{ borrower.surname }} {{ borrower.name }} {{ borrower.patronymic }}</p>
          <p><strong>Паспорт: </strong>{{ borrower.passport.series }} {{ borrower.passport.number }}</p>
        </template>
        <template #footer v-if="this.actions !== null">
          <SplitButton label="Действия" icon="pi pi-bolt" :model="generateItems({application, borrower, event})"></SplitButton>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractForm from "@/components/AbstractForm.vue";
import FormModel from "@/model/FormModel";
import FunctionsService from "@/service/FunctionsService";
import ResponseService from "@/service/ResponseService";
import FilterDateService from "@/service/FilterDateService";

export default {
  name: "ApplicationSearchForm",
  components: {AbstractForm},
  props: {
    actions: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      searchForm: {
        applicationUuid: new FormModel('UUID заявки')
      },
      application: null,
      borrower: null,
      event: null
    }
  },
  methods: {
    generateItems(data) {
      return this.actions(data);
    },
    formatDate(date) {
      return FilterDateService.dateToString(date)
    },
    searchApplication() {
      let uuid = this.searchForm.applicationUuid.value
      if (typeof uuid === 'string') {
        uuid = this.searchForm.applicationUuid.value.trim()
      }

      this.formLoading = true
      FunctionsService.getApplication(uuid, true).then((res) => {
        if (res.error === true) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Ошибка поиска заявки',
            detail: res.feedback,
            life: 5000
          })
          return;
        }
        this.application = res.application
        this.borrower = res.borrower
        this.event = res.event
      })
      .catch((err) => {
        ResponseService.handleErrorResponseWithForm(err, this.searchForm, this.$toast)
      })
      .finally(() => {
        this.formLoading = false
      })
    },
    setLoading(value) {
      this.loading = value
    },
    clear() {
      this.$refs.form.clear();
    }
  }
}
</script>