<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Запросы на удаление ИЧ</template>
        <template #content>
          <AbstractTable ref="table" code="deleteInformationParts" display-filter="menu">
            <template #additionalButtons>
              <Button
                icon="pi pi-refresh"
                class="p-button-rounded p-button-outlined"
                @click="$refs.table.getData()"
              ></Button>
            </template>
            <template #id="row">
              <IdChip
                view="delete_information_part_view"
                :id="row.data.id"
                :menu-items="generateItems(row.data)"
                toast-template='Идентификатор запроса на удаление ИЧ "{v}" скопирован'
              />
            </template>
            <template #applicationUuid="row">
              <ValueChip
                :value="row.data.applicationUuid"
                toast-template='UUID заявки "{v}" скопирован'
              />
            </template>
            <template #cbUuid="row">
              <ValueChip
                :value="row.data.cbUuid"
                toast-template='ЦБ УИД "{v}" скопирован'
              />
            </template>
            <template #bureau="row">
              {{ $filters.translate($store, 'deleteInformationPartBureaus', row.data.bureau) }}
            </template>
            <template #status="row">
              <StatusChip
                :value="row.data.status"
                compendium="processStatuses"
              />
            </template>
            <template #filename="row">
              <ValueChip
                v-if="row.data.filename"
                :value="row.data.filename"
                toast-template='Имя файла запроса на удаление ИЧ "{v}" скопировано'
              />
            </template>
          </AbstractTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable.vue";
import ValueChip from "@/components/Common/ValueChip.vue";
import IdChip from "@/components/Common/IdChip.vue";
import StatusChip from "@/components/Common/StatusChip.vue";
import DeleteInformationPartActions from "@/helpers/actions/deleteInformationPartActions";

export default {
  name: 'DeleteInformationPartsTable',
  components: {StatusChip, IdChip, ValueChip, AbstractTable},
  methods: {
    generateItems(data) {
      const actions = new DeleteInformationPartActions(data);

      actions.beforeResend = () => this.$refs.table.setLoading(true);
      actions.afterResend = () => this.refresh();

      return actions.getMenuItems();
    },
    refresh() {
      this.$refs.table.getData();
    }
  }
}
</script>


